<template>
  <app-main>
    <template #headerText>
      <h4>Coming Soon</h4>
    </template>
    <p class="text-center">Thank you for stopping by. The promotion commences 23/03/22 at 9:00am AEDT. Please visit us during the promotional period, to enter. We look forward to receiving your entry then!</p>
  </app-main>

</template>
<script>


export default {
    name:'AppLanding',
    computed: {
      
    }
}
</script>

<style>

</style>
